const { resolve } = require('path')

module.exports = async function module (moduleOptions) {
  const options = Object.assign({}, moduleOptions)
  
  // Add flatpickr css
  this.options.css.push('flatpickr/dist/flatpickr.css')

  
  this.addPlugin({
    src: resolve(__dirname, 'templates/plugin.js'),
    fileName: 'nuxt-flatpickr.js',
    ssr: false,
    options
  })
}
